body, html {
  overflow-x: hidden;
}

.imageRow{
    width: 100%;
    height: 80vh;
    flex-direction: row;
    display: flex;
    position: relative;
}

@media (max-width: 800px) {
  .imageRow{
    height: 50vh;
  }
}

.image-container{
    width: 50%;
    position: relative;
}
.top-row .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#top-left-img {
  background: rgba(0, 0, 0, 0.5);
  background-image: url('./images/Home_topLeft.png');
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
}

.overlay-text{
    position:absolute ;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 4rem;
    font-family: "Quicksand", sans-serif;
    align-items: center; /** Y-axis align **/
    justify-content: center; /** X-axis align **/
    background: -webkit-linear-gradient(left, white , #DBC7C3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 9;
    width: 100%;
    text-align: center;
}

.who-we-are{
    background-image: url('./images/Home_bottomLeft.png') ;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
    height: 100%;
}

.who-we-are p{
    color: #DBC7C3;
    font-size: 2.5rem;
    text-align: center;
    white-space: pre-wrap;
}

@media (max-width: 800px) {
  .overlay-text {
    font-size: 2rem;
  }
  .who-we-are p {
    font-size: 2rem;
  }
}

.text-content {
    padding: 1rem 4rem;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    background: #ebebeb;
}

.home-modal-dialog {
  width: 90vw;
  max-width: 90vw;
  margin: auto;
  margin-block: 1rem;
}
.launching-modal{
  height: 300%;
  z-index: 9999;
  background: #6D6D6DCC;
}

.launching-submodal{
  display: flex;
  justify-content: center;
  align-items: center;
}

.supporting-organisations-title{
  font-size: 2.5rem;
  text-align: center;
  color: #760326;
  font-style: italic;
}

@media (max-width: 800px) {
  .supporting-organisations-title{
    font-size: 1.8rem;
  }
}

.launching-title{
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #760326;
}

@media (max-width: 800px) {
  .launching-title{
    font-size: 1.2rem;
  }
}

.launching-subtitle {
  font-size: 1rem;
  font-weight: bold;
  color: #760326;
}

.launching-text{
  padding: 0.5rem;
  font-size: 1rem;
  text-align: left;
  color: #000000A8;
}

.launching-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #0D1317B8;
}

.launch-register-now-btn{
  gap: 12px;
  background: #760326;
  margin: 0px auto 10px auto;
  cursor: pointer;
}

.launch-register-now-btn-txt{
  letter-spacing: 0px;
  text-align: center;
  color: white;
}

.cross-icon-div{
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.register-now-container{
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.register-now{
  height: 40px;
  width: 150px;
  padding: 16px 48px;
  gap: 12px;
  background: #760326;
}

.register-now-text{
  position: relative;
  left: -26px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 7px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
}

.launch-logo-desktop{
  height: 580px;
  object-fit: cover;
}
