.company-scorecard .image {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./images/mainBg.png');
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: 0em;
  text-align: center;
  color: #DBC7C3;
}

.company-scorecard-content {
  margin: 20px 30px;

  .cert-header {
    font-size: 19px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: left;
    color: #6D6E76;
  }

  .cert-header-date {
    font-size: 17px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: left;
  }

  .cert-line {
    border: 1px solid #D0C8C8;
    background: #D0C8C8;
  }

  .cert-text {
    margin-top: 20px;
    font-size: 17px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0px;
    color: #6D6E76;
  }
}

.cert-search {
  width: 100%;
  display: flex;
  width: 95%;
  max-width: 500px;
  margin: 2rem auto 1rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #97979780;
  border-radius: 5px;
  padding: 0.8rem 0.5rem;
}

.cert-search-text {
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  text-align: left;
  color: rgba(133, 129, 129, 0.82);
}

.cert-search-icon {
  height: 1.2rem;
  width: 1.2rem;
  margin: auto 0px;
  cursor: pointer;
}

.cert-download {
  display: inline-block;
  margin: auto 20px;
  border-radius: 20px;
  background: rgba(118, 3, 38, 1);
  box-shadow: 0px 10px 20px 0px rgba(54, 58, 120, 0.1);
  text-align: center;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
  color: white;
}

.company-details-table-container {
  margin: 2rem;
  overflow-x: scroll;
}

.company-details-table {
  color: #7F7F7F;
  width: 100%;
  border: 2px solid #BFBFBF;
  border-collapse: collapse;
  font-size: 0.8rem;

  tr {
    text-align: center;
  }

  thead {
    position: static;
    top: initial;
  }

  th {
    position: static;
    top: initial;
    border: 2px solid #BFBFBF;
    padding: 18px;
  }

  td {
    border: 2px solid #BFBFBF;
    color: #5f124f;
    font-weight: bold;
    padding: 12px 0;
  }
}

@media (max-width: 800px) {
  .company-details-table {
    font-size: 0.6rem;
  }
}

.company-details-table-pdf {
  color: #7F7F7F;
  width: 100%;
  table-layout: fixed;
  border: 2px solid #BFBFBF;
  border-collapse: collapse;

  tr {
    text-align: center;
  }

  thead {
    position: static;
    top: initial;
  }

  th {
    position: static;
    top: initial;
    border: 2px solid #BFBFBF;
    padding: 18px;
  }

  td {
    word-break: break-word;
    border: 2px solid #BFBFBF;
    color: #5f124f;
    font-weight: bold;
    padding: 12px 0;
  }
}

.pagination {
  font-size: 17px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  color: #656568;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 100%;
  overflow-x: scroll;
}

.active-page {
  color: #760326;
  cursor: pointer;
}

.inactive-page {
  text-decoration: underline;
  cursor: pointer;
}

.green-tick {
  color: #70ad47 !important;
}

.badge-image {
  width: 5rem;
  margin: auto;
}

@media (max-width: 800px) {
  .badge-image {
    width: 3rem;
  }
}

/* Add these new styles */
.column-filter {
  position: relative;
  cursor: pointer;
}

.filter-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}

.filter-selected {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  color: #760326;
  margin-top: 0.5rem;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filter-icon {
  transition: transform 0.2s ease;
}

.filter-icon.open {
  transform: rotate(180deg);
}

.filter-dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  min-width: 120px;
  max-height: 300px;
  overflow-y: auto;
}

.filter-option {
  font-size: 0.8rem;
  padding: 0.4rem 0.6rem;
  transition: background-color 0.2s;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.filter-option:hover {
  background-color: #f5f5f5;
}

.filter-option.selected {
  background-color: #760326;
  color: white;
}

.filter-option-text {
  flex: 1;
}

.filter-check {
  font-size: 0.8rem;
  color: white;
}

@media (max-width: 800px) {
  .filter-option {
    font-size: 0.6rem;
  }
  .filter-selected {
    font-size: 0.6rem;
  }
  .filter-check {
    font-size: 0.6rem;
  }
}
/* Adjust table header styles */
.company-details-table th {
  min-width: 120px;
  vertical-align: top;
}